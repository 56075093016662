import React, { Component } from 'react';
import styled from 'styled-components';

const PhotoStyled = styled.div`
	margin: 0 auto;
	width: 100%;
	@media (min-width: 768px) {
		width: 600px;
	}
	@media (min-width: 1024px) {
		width: 625px;
	}
	@media (min-width: 1440px) {
		width: 880px;
		margin: 50px auto;
	}
`;

const SingleImg = styled.img`
	width: 100%;
`;

class SingleNewsPhoto extends Component {
	state = {
		isMobile: false,
	};

	constructor(props) {
		super(props);
		this.actionsOnResize = this.actionsOnResize.bind(this);
	}

	componentDidMount() {
		this.actionsOnResize();
		window.addEventListener('resize', this.actionsOnResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.actionsOnResize);
	}

	actionsOnResize() {
		if (typeof window !== 'undefined') {
			if (window.innerWidth < 768) {
				this.setState({
					isMobile: true,
				});
			} else if (window.innerWidth >= 768) {
				this.setState({ isMobile: false });
			}
		}
	}

	render() {
		const { data } = this.props;
		const { isMobile } = this.state;
		const photoUrl = (data && data.zdjecie && data.zdjecie[0] && data.zdjecie[0].url) || '';
		const photoUrlMobile = (data && data.zdjecieMobile && data.zdjecieMobile[0] && data.zdjecieMobile[0].url) || '';
		// eslint-disable-next-line no-nested-ternary
		const finalSrc = !isMobile ? photoUrl : photoUrlMobile !== '' ? photoUrlMobile : photoUrl;
		return (
			<PhotoStyled>
				<SingleImg src={finalSrc} />
			</PhotoStyled>
		);
	}
}

export default SingleNewsPhoto;
