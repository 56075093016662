import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import SEO from '../components/seo/seo';
import SingleNewsExtendedContent from '../components/singleNews/singleNewsExtendedContent';
import ShareSingleNews from '../components/singleNews/ShareSingleNews';
import BackgroundChange from '../components/backgroundChange/BackgroundChange';
import getSlug from '../utils/getSlug';
import { toggleIsIntroRendered } from '../state/app';

const StyledSingleNewsPageWrapper = styled.div``;

const StyledTitleWrapper = styled.div`
	align-items: baseline;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 0 20px;
	width: 100%;
	@media (min-width: 768px) {
		flex-direction: row;
		padding: 0;
		width: 600px;
	}
	@media (min-width: 1024px) {
		width: 625px;
	}
	@media (min-width: 1440px) {
		width: 880px;
	}
`;

const StyledSingleNewsContentWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 23vh 0 20vh;
`;

const StyledNewsText = styled.div`
	color: var(--text-color);
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 10px;
	font-weight: ${({ theme }) => theme.fontWeight.black};
	line-height: 18px;
	text-transform: capitalize;
	//@media (min-width: 500px) {
	//	font-size: 12px;
	//	line-height: 20px;
	//}
	@media ${({ theme }) => theme.device.w_768} {
		font-size: 12px;
		line-height: 28px;
	}
	@media (min-width: 1200px) {
		font-size: 16px;
		line-height: 36px;
	}
	@media ${({ theme }) => theme.device.w_1920} {
		font-size: 17px;
		line-height: 50px;
	}
`;

const SingleNewsPage = ({
	data: { singleNewsPages, seoPojedynczaAktualnosc, globalShareNews, allNewsSinglePage },
	pageContext,
	isIntroRendered,
}) => {
	const activeSite = pageContext.site || '';
	const singleNewsPageData = singleNewsPages?.nodes?.[0] || [];
	const globalSeo = pageContext?.globals?.data?.globalSeo?.nodes?.[0] || [];
	const seoData = seoPojedynczaAktualnosc?.nodes?.[0] || [];
	const singleNewsPageContent = singleNewsPageData?.trescPodstrony || [];
	const singleNewsTitle = singleNewsPageData?.title || '';
	const shareNewsData = globalShareNews?.nodes?.[0]?.aktualnoscPodzielSie?.[0] || [];
	const arrayForBgChanges = [];
	const allNewsSinglePageSlug = allNewsSinglePage?.nodes?.[0]?.slug || '';
	arrayForBgChanges.push({ id: 'footer-el', bgColor: 'black' });
	const dispatch = useDispatch();
	useEffect(() => {
		// eslint-disable-next-line radix
		if (!isIntroRendered) {
			dispatch(toggleIsIntroRendered(true));
		}
	}, []);

	return (
		<StyledSingleNewsPageWrapper>
			<SEO globalSeo={globalSeo} seoData={seoData} />
			<StyledSingleNewsContentWrapper>
				<StyledTitleWrapper>
					<StyledNewsText as={Link} to={`/${getSlug(allNewsSinglePageSlug, activeSite)}`}>
						{activeSite === 'pl' ? '_Aktualności_' : '_News_'}
					</StyledNewsText>
					<StyledNewsText>{singleNewsTitle}</StyledNewsText>
				</StyledTitleWrapper>
				<SingleNewsExtendedContent activeSite={activeSite} data={singleNewsPageContent} />
				<ShareSingleNews data={shareNewsData} />
			</StyledSingleNewsContentWrapper>
			{arrayForBgChanges.length > 0 && <BackgroundChange arrayForBgChanges={arrayForBgChanges} />}
		</StyledSingleNewsPageWrapper>
	);
};

export default connect(
	(state) => ({
		isIntroRendered: state.app.isIntroRendered,
	}),
	null,
)(SingleNewsPage);

export const query = graphql`
	query($remoteId: ID, $site: String, $firstOtherLangSite: String) {
		singleNewsPages: allCraftAktualnosciAktualnosciEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
			nodes {
				slug
				title
				id: remoteId
				trescPodstrony {
					__typename
					... on Craft_trescPodstrony_naglowek_BlockType {
						naglowek
					}
					... on Craft_trescPodstrony_opis_BlockType {
						opis
					}
					... on Craft_trescPodstrony_zdjecie_BlockType {
						zdjecie {
							url
						}
						zdjecieMobile {
							url
						}
					}
					... on Craft_trescPodstrony_zdjecieDuze_BlockType {
						zdjecie {
							url
						}
						zdjecieMobile {
							url
						}
					}
					... on Craft_trescPodstrony_video_BlockType {
						videoUrl
						videoPlik {
							url
						}
					}
					... on Craft_trescPodstrony_modul_BlockType {
						wybierzModul {
							__typename
							typeHandle
							remoteId
							...modulNaglowekTlo
							...modulNaglowekZdjecie
							...modulProduktTabelka
							...modulNaglowekOpis
							...modulVideo
							...modulOpis
							...modulGaleria
							...modulNapiszDoNas
							...modulWszystkieProduktyZKategorii
						}
					}
				}
			}
		}
		singleNewsPagesOtherLang: allCraftAktualnosciAktualnosciEntry(
			filter: { remoteId: { eq: $remoteId }, language: { eq: $firstOtherLangSite } }
		) {
			nodes {
				slug
				id: remoteId
			}
		}
		globalCookies: allCraftCookiesGlobalSet(filter: { language: { eq: $site } }) {
			nodes {
				cookiesIkona {
					url
				}
				cookiesTekst
				cookiesNapisNaPrzycisku
			}
		}
		allNewsSinglePage: allCraftPojedynczePodstronyPojedynczePodstronyEntry(filter: { language: { eq: $site }, remoteId: { eq: 287 } }) {
			nodes {
				slug
				uri
			}
		}
		pojedynczeOtherLang: allCraftPojedynczePodstronyPojedynczePodstronyEntry(filter: { language: { eq: $firstOtherLangSite } }) {
			nodes {
				slug
				id: remoteId
			}
		}
		productPagesOtherLang: allCraftKategoriaProduktowKategoriaProduktowEntry(filter: { language: { eq: $firstOtherLangSite } }) {
			nodes {
				slug
				id: remoteId
			}
		}
		globalLogo: allCraftLogoGlobalSet(filter: { language: { eq: $site } }) {
			nodes {
				logoAnimacja {
					url
				}
			}
		}
		globalShareNews: allCraftAktualnoscPodzielSieGlobalSet(filter: { language: { eq: $site } }) {
			nodes {
				aktualnoscPodzielSie {
					... on Craft_aktualnoscPodzielSie_podzielSie_BlockType {
						naglowek
						logoFacebook {
							url
						}
						logoFacebookHover {
							url
						}
						logoTwitter {
							url
						}
						logoTwitterHover {
							url
						}
						logoLinkedin {
							url
						}
						logoLinkedinHover {
							url
						}
						ikonaKopiujLink {
							url
						}
						ikonaKopiujLinkHover {
							url
						}
						linkZostalSkopiowany
					}
				}
			}
		}
		footer: allCraftStopkaGlobalSet(filter: { language: { eq: $site } }) {
			nodes {
				... on Craft_stopka_GlobalSet {
					stopka {
						... on Craft_stopka_lewaKolumna_BlockType {
							tekst
						}
						... on Craft_stopka_prawaKolumna_BlockType {
							ikona {
								url
							}
							email
							nazwaFirmy
							adres
						}
						... on Craft_stopka_dolnaNawigacja_BlockType {
							wybierzPodstrony {
								slug
								title
							}
						}
						... on Craft_stopka_logoPleo_BlockType {
							linkDoStrony
							logo {
								url
							}
						}
						__typename
					}
				}
			}
		}
		menuModuleData: allCraftMenuMenuEntry(filter: { language: { eq: $site } }) {
			nodes {
				nazwaPodstrony
				opisPodstrony
				level
				remoteChildren {
					slug
					title
				}
				wybierzPodstrone {
					slug
				}
			}
		}
		submenuModuleData: allCraftSubmenuSubmenuEntry(filter: { language: { eq: $site } }) {
			nodes {
				nazwaPodstrony
				wybierzPodstrone {
					slug
				}
			}
		}
		socialMediaModuleData: allCraftSocialMediaGlobalSet(filter: { language: { eq: $site } }) {
			nodes {
				socialMedia {
					... on Craft_socialMedia_socialMedia_BlockType {
						linkUrl
						nazwa
						logo {
							url
						}
						logoZielone {
							url
						}
						widoczneWStopce
						widoczneWMenu
					}
				}
			}
		}
		seoPojedynczaAktualnosc: allCraftAktualnosciAktualnosciEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
			nodes {
				...seoPojedynczaAktualnosc
			}
		}
	}
`;
