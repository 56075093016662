import React, { Component } from 'react';
import styled from 'styled-components';
import PageContainer from '../common/PageContainer';

const PhotoStyled = styled.div`
	background-image: url(${(props) => props.bgImg});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	margin: 100px 0;
	width: 100vw;
	@media (min-width: 1240px) {
		background-attachment: fixed;
	}
`;

class SingleNewsLargePhoto extends Component {
	state = {
		isMobile: false,
	};

	constructor(props) {
		super(props);
		this.actionsOnResize = this.actionsOnResize.bind(this);
	}

	componentDidMount() {
		this.actionsOnResize();
		window.addEventListener('resize', this.actionsOnResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.actionsOnResize);
	}

	actionsOnResize() {
		if (typeof window !== 'undefined') {
			if (window.innerWidth < 768) {
				this.setState({
					isMobile: true,
				});
			} else if (window.innerWidth >= 768) {
				this.setState({ isMobile: false });
			}
		}
	}

	render() {
		const { data } = this.props;
		const { isMobile } = this.state;
		const photoUrl = (data && data.zdjecie && data.zdjecie[0] && data.zdjecie[0].url) || '';
		const photoUrlMobile = (data && data.zdjecieMobile && data.zdjecieMobile[0] && data.zdjecieMobile[0].url) || '';
		// eslint-disable-next-line no-nested-ternary
		const finalSrc = !isMobile ? photoUrl : photoUrlMobile !== '' ? photoUrlMobile : photoUrl;
		const moduleId = data?.id + data?.__typename || '';

		return (
			<PageContainer className="full-width" id={moduleId}>
				<PhotoStyled bgImg={finalSrc} />
			</PageContainer>
		);
	}
}

export default SingleNewsLargePhoto;
