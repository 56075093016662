import React, { Component } from 'react';
import DescriptionButton from '../descriptionButton/DescriptionButton';
import ProductTable from '../productTable/ProductTable';
import HeadlinePhoto from '../headlinePhoto/HeadlinePhoto';
import HeadlineBackground from '../headlineBackground/HeadlineBackground';
import HeaderDescription from '../headerDescription/HeaderDescription';
import GalleryModule from '../galleryModule/GalleryModule';
import HeadingButton from '../headingButton/HeadingButton';
import CategoriesProducts from '../categoriesProducts/CategoriesProducts';

class ModulesContent extends Component {
	render() {
		const {
			data,
			activeSite,
			chooseCategory,
			allProductPageSlug,
			productData,
			productSlug,
			productsPerCategoryData,
			isDifferentPage,
			pageTitleForBreadCrumbs,
		} = this.props;
		const moduleData = (data && data.wybierzModul) || '';
		if (Array.isArray(moduleData)) {
			return moduleData.map((item, key) => {
				switch (item.__typename) {
					case 'Craft_moduly_modulNaglowekZdjecie_Entry':
						return (
							<HeadlinePhoto
								// eslint-disable-next-line react/no-array-index-key
								key={key}
								data={item}
								activeSite={activeSite}
								chooseCategory={chooseCategory}
								allProductPageSlug={allProductPageSlug}
								productData={productData}
								productSlug={productSlug}
								isDifferentPage={isDifferentPage}
								pageTitleForBreadCrumbs={pageTitleForBreadCrumbs}
							/>
						);
					case 'Craft_moduly_modulOpis_Entry':
						// eslint-disable-next-line react/no-array-index-key
						return <DescriptionButton key={key} data={item} activeSite={activeSite} />;
					case 'Craft_moduly_modulProduktTabelka_Entry':
						// eslint-disable-next-line react/no-array-index-key
						return <ProductTable key={key} data={item} activeSite={activeSite} />;
					case 'Craft_moduly_modulNaglowekTlo_Entry':
						return (
							<HeadlineBackground
								// eslint-disable-next-line react/no-array-index-key
								key={key}
								data={item}
								activeSite={activeSite}
								productData={productData}
								allProductPageSlug={allProductPageSlug}
							/>
						);
					case 'Craft_moduly_modulNaglowekOpis_Entry':
						// eslint-disable-next-line react/no-array-index-key
						return <HeaderDescription key={key} data={item} activeSite={activeSite} />;
					case 'Craft_moduly_modulGaleria_Entry':
						// eslint-disable-next-line react/no-array-index-key
						return <GalleryModule key={key} data={item} activeSite={activeSite} />;
					case 'Craft_moduly_modulNapiszDoNas_Entry':
						// eslint-disable-next-line react/no-array-index-key
						return <HeadingButton key={key} data={item} activeSite={activeSite} />;
					case 'Craft_moduly_modulWszystkieProduktyZKategorii_Entry':
						// eslint-disable-next-line react/no-array-index-key
						return <CategoriesProducts key={key} productData={productsPerCategoryData} activeSite={activeSite} />;
					default:
						// eslint-disable-next-line react/no-array-index-key
						return <h1 key={key}>Brak obsługi dla {item?.__typename}</h1>;
				}
			});
		}
		return null;
	}
}

export default ModulesContent;
