/*eslint-disable*/
import React, { Component } from 'react';
import styled from 'styled-components';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

const ShareButtonsWrapper = styled.div`
	color: var(--text-color);
	display: block;
	margin-top: 20vh;
	padding: 0 20px;
	width: 100%;
	@media (min-width: 768px) {
		padding: 0;
		width: 600px;
	}
	@media (min-width: 1024px) {
		width: 625px;
	}
	@media (min-width: 1440px) {
		width: 880px;
	}
`;

const CopyWrapper = styled.div`
	margin-top: 30px;
	position: relative;
	z-index: 100;
`;

const CopyTooltip = styled.p`
	background-color: var(--background-color);
	border: 1px solid var(--text-color);
	box-shadow: 0 6px 8px 0 rgba(16, 6, 31, 0.27);
	display: flex;
	flex-wrap: nowrap;
	font-size: 20px;
	height: 50px;
	left: -60px;
	line-height: 24px;
	opacity: 0;
	padding: 15px;
	pointer-events: none;
	position: absolute;
	top: 0;
	transform: translateY(0);
	transition: 0.2s ease-in;
	white-space: nowrap;
	z-index: 20;
	&.visible {
		opacity: 1;
		transform: translateY(-80px);
	}
	&.visible-payment {
		left: 0;
		opacity: 1;
		transform: translateY(-30px);
	}
	@media (min-width: 375px) {
		left: 0;
	}
	@media (min-width: 768px) {
		padding: 15px 35px;
	}
`;
const Header = styled.p`
	display: table;
	font-weight: 800;
	margin-bottom: 20px;
	position: relative;
	text-transform: uppercase;
	width: 100%;
	z-index: 2;

	span {
		transition: background-color 0.4s ease-in-out;
		background-color: var(--background-color);
		padding-right: 20px;
	}

	&:before {
		background-color: var(--text-color);
		bottom: 5px;
		content: '';
		height: 2px;
		left: 0;
		position: absolute;
		width: 100%;
		z-index: -1;
	}
`;

const StyledSocialMediaWrapper = styled.div`
	display: flex;
	flex-direction: row;
	height: 60px;
	justify-content: flex-start;
`;

const StyledSingleSocialMediaWrapper = styled.div`
	margin-right: 15px;
	position: relative;
	width: 60px;
	div {
		transition: transform 0s ease-in-out, background-image 0s ease-in-out, filter 0s ease-in-out;
		transition-delay: 0.1s;
	}
	svg {
		height: 60px;
		left: 0;
		position: absolute;
		top: 0;
		width: 60px;
	}
	&.hover {
		div {
			filter: unset;
			background-image: url(${(props) => props.iconHover});
		}
		svg {
			.progress__circle {
				stroke-width: 1px;
				stroke: transparent;
				transition: stroke 0.2s ease-in-out;
			}
			.progress__path {
				stroke-dashoffset: 0;
			}
		}
	}
	.progress__circle {
		fill: none;
		stroke: var(--text-color);
		stroke-width: 1px;
		transition: stroke 0.7s ease-in-out;
	}
	.progress__path {
		fill: none;
		stroke: #04e99d;
		stroke-dasharray: 1;
		stroke-dashoffset: 1;
		stroke-width: 1px;
		transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);
	}
`;

const StyledSocialMediaIcon = styled.div`
	filter: invert(var(--filter));
	background-image: url(${(props) => props.icon});
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
	height: 60px;
	left: 0;
	position: absolute;
	top: 0;
	transition: transform 0.2s ease-in-out;
	width: 60px;
	&.copy-url {
		width: 30px;
		height: 35px;
		top: 12px;
		left: 15px;
	}
	&.mobile {
		position: relative;
	}
`;

class ShareSingleNews extends Component {
	state = {
		isTooltipVisible: false,
	};

	copyToClipboard() {
		const dummy = document.createElement('input');
		const text = window.location.href;
		document.body.appendChild(dummy);
		dummy.value = text;
		dummy.select();
		document.execCommand('copy');
		document.body.removeChild(dummy);
		this.setState({ isTooltipVisible: true });

		setTimeout(() => {
			this.setState({ isTooltipVisible: false });
		}, 2500);
	}

	render() {
		const { data } = this.props;
		const { isTooltipVisible } = this.state;
		const header = data?.naglowek || '';
		const facebookIcon = data?.logoFacebook?.[0]?.url || '';
		const facebookIconHover = data?.logoFacebookHover?.[0]?.url || '';
		const twitterIcon = data?.logoTwitter?.[0]?.url || '';
		const twitterIconHover = data?.logoTwitterHover?.[0]?.url || '';
		const linkedinIcon = data?.logoLinkedin?.[0]?.url || '';
		const linkedinIconHover = data?.logoLinkedinHover?.[0]?.url || '';
		const copyUrlIcon = data?.ikonaKopiujLink?.[0]?.url || '';
		const copyUrlIconHover = data?.ikonaKopiujLinkHover?.[0]?.url || '';
		const copyLinkInfo = data?.linkZostalSkopiowany || '';
		let siteUrl = '';
		if (typeof window !== 'undefined') {
			siteUrl = window.location.href || '';
		}
		return (
			<ShareButtonsWrapper>
				<Header className="h8">
					<span>{header}</span>
				</Header>
				<StyledSocialMediaWrapper>
					<FacebookShareButton style={{ cursor: 'none !important' }} className="icon" url={siteUrl}>
						<StyledSingleSocialMediaWrapper
							className="footer-social-media custom-cursor-animation"
							iconHover={facebookIconHover}
						>
							<StyledSocialMediaIcon
								className="footer-social-media custom-cursor-animation"
								icon={facebookIcon}
								iconHover={facebookIconHover}
							/>
							<svg aria-hidden="true" className="progress" width="70" height="70" viewBox="0 0 70 70">
								<path
									className="progress__circle"
									d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
												32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
								/>
								<path
									className="progress__path"
									d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
												32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
									pathLength="1"
								/>
							</svg>
						</StyledSingleSocialMediaWrapper>
					</FacebookShareButton>
					<TwitterShareButton style={{ cursor: 'none !important' }} className="icon" url={siteUrl}>
						<StyledSingleSocialMediaWrapper
							className="footer-social-media custom-cursor-animation"
							iconHover={twitterIconHover}
						>
							<StyledSocialMediaIcon
								className="footer-social-media custom-cursor-animation"
								icon={twitterIcon}
								iconHover={twitterIconHover}
							/>
							<svg aria-hidden="true" className="progress" width="70" height="70" viewBox="0 0 70 70">
								<path
									className="progress__circle"
									d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
												32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
								/>
								<path
									className="progress__path"
									d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
												32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
									pathLength="1"
								/>
							</svg>
						</StyledSingleSocialMediaWrapper>
					</TwitterShareButton>
					<LinkedinShareButton style={{ cursor: 'none !important' }} className="icon" url={siteUrl}>
						<StyledSingleSocialMediaWrapper
							className="footer-social-media custom-cursor-animation"
							iconHover={linkedinIconHover}
						>
							<StyledSocialMediaIcon
								className="footer-social-media custom-cursor-animation"
								icon={linkedinIcon}
								iconHover={linkedinIconHover}
							/>
							<svg aria-hidden="true" className="progress" width="70" height="70" viewBox="0 0 70 70">
								<path
									className="progress__circle"
									d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
												32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
								/>
								<path
									className="progress__path"
									d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
												32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
									pathLength="1"
								/>
							</svg>
						</StyledSingleSocialMediaWrapper>
					</LinkedinShareButton>
					<CopyWrapper className="icon" url={siteUrl}>
						<StyledSingleSocialMediaWrapper
							className="footer-social-media custom-cursor-animation"
							onClick={this.copyToClipboard.bind(this)}
							iconHover={copyUrlIconHover}
						>
							<StyledSocialMediaIcon
								className="footer-social-media custom-cursor-animation copy-url"
								icon={copyUrlIcon}
								iconHover={copyUrlIconHover}
							/>
							<svg aria-hidden="true" className="progress" width="70" height="70" viewBox="0 0 70 70">
								<path
									className="progress__circle"
									d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
												32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
								/>
								<path
									className="progress__path"
									d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
												32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
									pathLength="1"
								/>
							</svg>
						</StyledSingleSocialMediaWrapper>
						<CopyTooltip className={isTooltipVisible ? 'visible' : ''}>{copyLinkInfo}</CopyTooltip>
					</CopyWrapper>
				</StyledSocialMediaWrapper>
			</ShareButtonsWrapper>
		);
	}
}

export default ShareSingleNews;
