import React from 'react';
import SingleNewsHeader from './SingleNewsHeader';
import SingleNewsDescription from './SingleNewsDescription';
import SingleNewsPhoto from './SingleNewsPhoto';
import SingleNewsLargePhoto from './SingleNewsLargePhoto';
import SingleVideo from './SingleVideo';
import ModulesContent from './ModulesContent';

const SingleNewsExtendedContent = ({
	data,
	activeSite,
	allProductPageSlug,
	productData,
	productsPerCategoryData,
	isDifferentPage,
	pageTitleForBreadCrumbs,
}) => {
	if (Array.isArray(data)) {
		return data.map((block, i) => {
			switch (block.__typename) {
				case 'Craft_trescPodstrony_naglowek_BlockType':
					// eslint-disable-next-line react/no-array-index-key
					return <SingleNewsHeader key={i} data={block} activeSite={activeSite} />;
				case 'Craft_trescPodstrony_opis_BlockType':
					// eslint-disable-next-line react/no-array-index-key
					return <SingleNewsDescription key={i} data={block} activeSite={activeSite} />;
				case 'Craft_trescPodstrony_zdjecie_BlockType':
					// eslint-disable-next-line react/no-array-index-key
					return <SingleNewsPhoto key={i} data={block} activeSite={activeSite} />;
				case 'Craft_trescPodstrony_zdjecieDuze_BlockType':
					// eslint-disable-next-line react/no-array-index-key
					return <SingleNewsLargePhoto key={i} data={block} activeSite={activeSite} />;
				case 'Craft_trescPodstrony_video_BlockType':
					// eslint-disable-next-line react/no-array-index-key
					return <SingleVideo key={i} data={block} activeSite={activeSite} />;
				case 'Craft_trescPodstrony_modul_BlockType':
					// eslint-disable-next-line react/no-array-index-key
					return (
						<ModulesContent
							// eslint-disable-next-line react/no-array-index-key
							key={i}
							data={block}
							activeSite={activeSite}
							productData={productData}
							productsPerCategoryData={productsPerCategoryData}
							allProductPageSlug={allProductPageSlug}
							isDifferentPage={isDifferentPage}
							pageTitleForBreadCrumbs={pageTitleForBreadCrumbs}
						/>
					);
				default:
					return <p>Brak obsługi dla {block.__typename}</p>;
				// return null;
			}
		});
	}

	return null;
};

export default SingleNewsExtendedContent;
